<script>
	
	import { onMount, onDestroy, setContext, getContext } from 'svelte';
	import * as util from '@/util'

	const {
		selected_location,
		all_appointment_types,
	} = getContext('schedulerStore');

	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 


	
	export function validate() {

		if (field.required && (!value || value === '' || value == 'any')) {

			return `${field.label || 'This field'} is required`;
		}
		return null;
	}
	


	let select_dropdown_appointement_types = []
	select_dropdown_appointement_types = structuredClone($all_appointment_types)

	// Remove default 'Appointment Type' option for non Rev-EHR
	if (! $selected_location?.slotsConstrainedByEHRType) {
		let any_appointment_type_index = select_dropdown_appointement_types.findIndex(item => item.appointmentTypeId == 'any')
		if (any_appointment_type_index != -1) {
			select_dropdown_appointement_types.splice(any_appointment_type_index, 1)
		}
	}

	function updateAppointmentType(event) {

		if (event.target.value) {
			util.selectAppointmentType(Number(event.target.value))
		} else {
			util.selectAppointmentType()
		}
		
	}


</script>


<div class="field field--type-appointment-type" class:field--inset-label={field.inset_label} bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		<select class="field__input field__input--select" id={field.field_id}  bind:value={value} on:change={() => updateAppointmentType(event)}>

			<option value="any">Select ></option>

			{#each select_dropdown_appointement_types as appointment_type}
				<option value={appointment_type?.appointmentTypeId}>{appointment_type.appointmentTypeLabel || appointment_type.appointmentTypeName}</option>
			{/each}

		</select>

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>
