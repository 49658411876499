
import { writable, derived } from 'svelte/store';

export function createStore() {

	const dev = writable(false);

	const component_width = writable(null);

	const root_element = writable();

	const loading_slots = writable(false);

	const api_working = writable(false);

	const show_modal = writable(false);

	const submitted_form_data = writable({});

	const slot_data = writable({});

	const pagination_index = writable(0);

	const prop_success_redirect_url = writable();
	const prop_account_id = writable();
	const prop_location_id = writable();
	const prop_doctor_id = writable();

	const selected_slot_id = writable();
	const selected_location_id = writable();
	const selected_doctor_id = writable();
	const selected_appointment_type_id = writable();

	const selected_location = derived(
		[slot_data, selected_location_id],
		([$slot_data, $selected_location_id]) =>
			$slot_data?.locations?.find(item => item.locationSamuraiId == $selected_location_id)
	);

	const selected_doctor = derived(
		[selected_location, selected_doctor_id],
		([$selected_location, $selected_doctor_id]) =>
			$selected_location?.doctors?.find(item => item.doctorOesId == $selected_doctor_id)
	);

	const selected_appointment_type = derived(
		[selected_location, selected_appointment_type_id],
		([$selected_location, $selected_appointment_type_id]) =>
			$selected_location?.appointmentTypes?.find(item => item.appointmentTypeId == $selected_appointment_type_id)
	);

	const all_appointment_types = derived(
		[selected_location],
		([$selected_location]) => {

			let _all_appointment_types = []

			if (! $selected_location) return []

			if ($selected_location.slotsConstrainedByEHRType) {

				// Get appointmentTypes from doctor slots
				for (const doctor of $selected_location.doctors) {

					for (const slot of doctor.slots) {

						for (const appointmentType of slot.appointmentTypes) {

							_all_appointment_types.push(appointmentType)

						}

					}

				}

			} else {

				_all_appointment_types = $selected_location.appointmentTypes

				let default_appointment_type = _all_appointment_types.find(item => item.isDefault)

				if (_all_appointment_types.length > 1 && ! default_appointment_type) {

					let any_appointment_type = {
						"appointmentTypeId": 'any',
						"appointmentTypeName": "Appointment Type",
						"appointmentTypeLabel": "Appointment Type",
						"isDefault": false
					}

					_all_appointment_types.unshift(any_appointment_type)

				}

			}

			
			// Make appointment types unique
			_all_appointment_types = _all_appointment_types.filter((obj, index, self) => index === self.findIndex((t) => (t.appointmentTypeId === obj.appointmentTypeId)))

			return _all_appointment_types

		}
	);


	const doctors_that_support_selected_appointment_type = derived(
		[selected_location, selected_appointment_type],
		([$selected_location, $selected_appointment_type]) => {
		
			let _doctors_that_support_selected_appointment_type = []

			if (! $selected_location?.doctors) return []
			if (! $selected_appointment_type) return []

			if ($selected_location?.slotsConstrainedByEHRType) {

				for (let doctor of $selected_location.doctors) {

					for (let slot of doctor.slots) {
					
						for (let appointment_type of slot.appointmentTypes) {

							if (appointment_type.appointmentTypeId == $selected_appointment_type.appointmentTypeId && ! _doctors_that_support_selected_appointment_type.find(item => item.doctorOesId == doctor.doctorOesId)) {

								_doctors_that_support_selected_appointment_type.push(doctor)

							}

						}

					}

				}

			} else {

				_doctors_that_support_selected_appointment_type = $selected_location.doctors

			}

			return _doctors_that_support_selected_appointment_type

		}
	);



	const request_appointment_complete = writable(false);

	const form_submissions = writable([]);

	const form_schema = writable({});


	const last_completed_form_submission = derived(
		[form_submissions],
		([$form_submissions]) =>
			$form_submissions[$form_submissions.length-1]
	);

	const last_completed_appointment_details = writable({});


	return {
		dev,
		component_width,
		root_element,
		loading_slots,
		api_working,
		show_modal,
		submitted_form_data,
		slot_data,
		pagination_index,
		prop_success_redirect_url,
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		selected_slot_id,
		selected_location_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_location,
		selected_doctor,
		selected_appointment_type,
		all_appointment_types,
		doctors_that_support_selected_appointment_type,
		request_appointment_complete,
		form_submissions,
		form_schema,
		last_completed_form_submission,
		last_completed_appointment_details,
	};
}
