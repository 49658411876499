<!-- comment out the following while developing -->


<svelte:options
	customElement={{
		tag: 'gsp-scheduler',
	}}
/>



<script>
	
	import * as util from '@/util'

	import { onMount, onDestroy, setContext, getContext } from 'svelte';
	import { createStore } from '@/store.js';

	import global_css from './assets/css/global.css?raw';
	
	import Slots from '@/lib/Slots.svelte';
	import Modal from '@/lib/Modal.svelte';


	const store = createStore();
	setContext('schedulerStore', store);

	util.setStore(store);

	const {
		dev,
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		prop_form,
		prop_form_fields_update,
		selected_slot_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_doctor,
		selected_location_id,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		submitted_form_data,
		slot_data,
		

	} = getContext('schedulerStore');


	export let account_id = ''
	if (account_id) $prop_account_id = account_id

	export let location_id = ''
	if (location_id) {
		$prop_location_id = location_id
		util.selectLocation(location_id)
	}

	export let doctor_id = ''
	if (doctor_id) {
		$prop_doctor_id = doctor_id
		util.selectDoctor(doctor_id)
	}

	export let form = null
	$: if (form) $prop_form = JSON.parse(form)

	export let form_fields_update = null
	$: if (form_fields_update) $prop_form_fields_update = JSON.parse(form_fields_update)


	export let color_background = '#fff';
	export let color_text = 'inherit';
	export let color_light_border = '#e0e0e0';
	export let color_primary_action = '#024e73';

	const desktop_loading_src = new URL('./assets/skeleton-desktop.svg', import.meta.url).href
	const mobile_loading_src = new URL('./assets/skeleton-mobile.svg', import.meta.url).href

	

/*******************************************************/
/* Get slot data
/*******************************************************/

	if ($dev) {

		//$: console.log($slot_data)

		$prop_account_id = 1718; // Seashore

		// Custom Forms
		//$prop_account_id = 1960 // Elevated Eyecare (Revolution EHR)
		//$prop_account_id = 2060 // Little Lake Family Eye Care
		//$prop_account_id = 2332 // Lindsay Optometry, https://www.lindsayoptometry.com/contact-us/appointment-request-form/
		//$prop_account_id = 2444 // Excellence Eye Care
		$prop_account_id = 2696 // Towne Lake Eye Associates
		//$prop_account_id = 2807 // Premier Eye Associates (Revolution EHR), https://www.premiereyedoctors.com/ 
		//$prop_account_id = 3360 // Complete Family Eyecare
		//$prop_account_id = 3709 // 360 Eyecare (ehrType == 'oop'), health card number: 2808940882
		//$prop_account_id = 4450 // The Eye Site, https://www.eyesitemeridian.com/contact-us/appointment-request-form/
		//$prop_account_id = 506 // Texas State Optical - Mckinney



		// Random accounts
		//$prop_account_id = 3360; 
		//$prop_account_id = 135; //Yesnick
		//$prop_account_id = 3404; //Not Revolution EHR
		//$prop_account_id = 3404; //Simmons
		//$prop_account_id = 135; // NON Rev EHR
		//$prop_account_id = 3843;
		//$prop_account_id = 4340; //Dr. Mariam Hermiz
		//$prop_account_id = 107
		//$prop_account_id = 4119 //ehrType": "oop"
		//$prop_location_id = 4107

	}
	
	
	onMount(() => {
		util.getSlotData();
	})


</script>


{@html `<style id="site-css">

	${global_css}

</style>`}


<div class="scheduler" 
	class:scheduler-modal-active={$show_modal}
	bind:this={$root_element}
	class:tablet={$component_width < 800 }
	class:mobile={$component_width <= 520}
	bind:clientWidth={$component_width}
	data-admin-login={`https://app.getsetpro.com/u/${$prop_account_id}/scheduler${($selected_location_id ? '/'+$selected_location_id : '')}`}
	style="
		--color_background: {color_background};
		--color_text: {color_text};
		--color_light_border: {color_light_border};
		--color_primary_action: {color_primary_action};
		--success: #04cb58;
		--danger: #e23807;
	">
	

	{#if $loading_slots}
		<div class="loading-animation">
			<img src={desktop_loading_src} alt="Loading..." class="desktop" />
			<img src={mobile_loading_src} alt="Loading..." class="mobile"	/>
		</div>
	
	{:else}

		{#if ! $loading_slots}

			{#if ! $prop_account_id}
				<div class="error">No Account ID</div>
			{/if}

			{#if $slot_data}
				<Slots />
			{/if}
			
		{/if}

		
		{#if $show_modal}

			<Modal 
				style="
					--color_background: {color_background};
					--color_text: {color_text};
					--color_light_border: {color_light_border};
					--color_primary_action: {color_primary_action};
					--success: #04cb58;
					--danger: #e23807;
				"
			/>

		{/if}

	{/if}


</div>

{#if $dev}


<br><br><br><br>

<hr size="1">

<h2 style="color:red">DEV MODE</h2>


selected_location_id: {$selected_location_id}<br>
selected_appointment_type_id: {$selected_appointment_type_id}<br>
selected_doctor_id: {$selected_doctor_id}<br>
selected_doctor name: {$selected_doctor?.doctorName}<br>
selected_slot_id: {$selected_slot_id}<br>


<a href="https://app.getsetpro.com/u/{$prop_account_id}/scheduler{($selected_location_id ? '/'+$selected_location_id : '')}" target="_blank">Admin Login</a>

<br><br>
Form Submission:<br>
{JSON.stringify($submitted_form_data)}

{/if}
